const TRANSLATION_KEYS = {
  JANUARY: "JANUARY",
  FEBRUARY: "FEBRUARY",
  MARCH: "MARCH",
  APRIL: "APRIL",
  MAY: "MAY",
  JUNE: "JUNE",
  JULY: "JULY",
  AUGUST: "AUGUST",
  SEPTEMBER: "SEPTEMBER",
  OCTOBER: "OCTOBER",
  TRANSACTIONS: "TRANSACTIONS",
  NOVEMBER: "NOVEMBER",
  DECEMBER: "DECEMBER",
  MONDAY_SHORT: "MONDAY_SHORT",
  TUESDAY_SHORT: "TUESDAY_SHORT",
  WEDNESDAY_SHORT: "WEDNESDAY_SHORT",
  THURSDAY_SHORT: "THURSDAY_SHORT",
  FRIDAY_SHORT: "FRIDAY_SHORT",
  SATURDAY_SHORT: "SATURDAY_SHORT",
  SUNDAY_SHORT: "SUNDAY_SHORT",
  START_DATE: "START_DATE",
  END_DATE: "END_DATE",
  WELCOME: "WELCOME",
  PAID: "PAID",
  GRAVITY_DESC_LOGIN: "GRAVITY_DESC_LOGIN",
  PIN: "PIN",
  CANCEL_TICKET: "CANCEL_TICKET",
  SIGN_IN: "SIGN_IN",
  USERNAME: "USERNAME",
  PASSWORD: "PASSWORD",
  SOMETHING_WENT_WRONG: "SOMETHING_WENT_WRONG",
  PLEASE_WAIT_FOR_LOADER: "PLEASE_WAIT_FOR_LOADER",
  GAMES: "GAMES",
  PLACED_BETS: "PLACED_BETS",
  BALANCE: "BALANCE",
  LIMIT: "LIMIT",
  LOGOUT: "LOGOUT",
  SIGN_IN_TITLE: "SIGN_IN_TITLE",
  ENTER_THE_BARCODE: "ENTER_THE_BARCODE",
  CHECK: "CHECK",
  BARCODE: "BARCODE",
  LAST_TICKET: "LAST_TICKET",
  CHECK_AGAIN: "CHECK_AGAIN",
  PAY: "PAY",
  TICKET_IS_PAID: "TICKET_IS_PAID",
  TICKET_SHOULD_BE_PAID_OUT: "TICKET_SHOULD_BE_PAID_OUT",
  DATE: "DATE",
  ROUND_ID: "ROUND_ID",
  BET_AMOUNT: "BET_AMOUNT",
  WINNING_AMOUNT: "WINNING_AMOUNT",
  PAID_AMOUNT: "PAID_AMOUNT",
  PAYMENT_TIME: "PAYMENT_TIME",
  BET_STATUS: "BET_STATUS",
  WON: "WON",
  LOST: "LOST",
  PENDING: "PENDING",
  RETURNED: "RETURNED",
  TICKETS: "TICKETS",
  OVERVIEW: "OVERVIEW",
  GAME: "GAME",
  SEARCH_BARCODE: "SEARCH_BARCODE",
  STATUS: "STATUS",
  RESET: "RESET",
  APPLY: "APPLY",
  WINNING_PAID: "WINNING_PAID",
  ALL_TICKETS: "ALL_TICKETS",
  WON_TICKETS: "WON_TICKETS",
  LOST_TICKETS: "LOST_TICKETS",
  BETS_ACCEPTED: "BETS_ACCEPTED",
  GAMES_NAV: "GAMES_NAV",
  STAKE: "STAKE",
  TICKET_STAKE: "TICKET_STAKE",
  WIN: "WIN",
  LOSE: "LOSE",
  CANCELED: "CANCELED",
  UNKNOWN: "UNKNOWN",
  CALCULATION: "CALCULATION",
  ON_CANCEL: "ON_CANCEL",
  TOTAL_BET: "TOTAL_BET",
  TOTAL_WIN_AMOUNT: "TOTAL_WIN_AMOUNT",
  TOTAL_ODDS: "TOTAL_ODDS",
  POSSIBLE_WIN: "POSSIBLE_WIN",
  BET_TYPE: "BET_TYPE",
  SINGLE: "SINGLE",
  EXPRESS: "EXPRESS",
  GAME_NAME: "GAME_NAME",
  PROVIDERS: "PROVIDERS",
  ADMIN: "ADMIN",
  DEPOSIT: "DEPOSIT",
  WITHDRAW: "WITHDRAW",
  SUCCESS: "SUCCESS",
  BALANCE_CLUB: "BALANCE_CLUB",
  CURRENT: "CURRENT",
  BALANCE_OPERATIONS: "BALANCE_OPERATIONS",
  CASHIER: "CASHIER",
  MARKET_NAME: "MARKET_NAME",
  TICKET_ID: "TICKET_ID",

  TICKET_STATUS: "TICKET_STATUS",
  DAY: "DAY",
  MY_BETS: "MY_BETS",
  COPY: "COPY",
  RESULT: "RESULT",
  FAIRNESS: "FAIRNESS",
  KEY: "KEY",
  CHECK_HASH_CODE: "CHECK_HASH_CODE",
  SALT: "SALT",
  HASH_CODE: "HASH_CODE",
  SCAN_TO_CHECK_FAIRNESS: "SCAN_TO_CHECK_FAIRNESS",
  TOTAL_POSSIBLE_WIN: "TOTAL_POSSIBLE_WIN",
  AMOUNT: "AMOUNT",
  MAX_PAY_OUT_AMOUNT_RANGE: "MAX_PAY_OUT_AMOUNT_RANGE",
};

export { TRANSLATION_KEYS as T };
