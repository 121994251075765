import { makeObservable, observable, action } from "mobx";

import { ProviderGamesResponse, ProvidersResponse } from "../models";
import { HttpResponse, providersController } from "../api";
import { RootStore } from ".";

export class ProvidersStore {
  private rootStore?: RootStore;

  public providers = new HttpResponse<ProvidersResponse>({
    partnerId: 0,
    partnerName: "",
    providers: [],
  });

  public providerGames = new HttpResponse<ProviderGamesResponse>({
    providerId: 1,
    games: [],
  });

  constructor(rootStore?: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      providers: observable,
      providerGames: observable,
      setProviders: action.bound,
      setProviderGames: action.bound,
    });
  }

  public setProviders(value: HttpResponse<ProvidersResponse>): void {
    this.providers = value;
  }

  public setProviderGames(value: HttpResponse<ProviderGamesResponse>): void {
    this.providerGames = value;
  }

  public async getProviders(): Promise<ProvidersResponse> {
    this.setProviders(this.providers.fetching());
    const data = await providersController.getProviders();
    this.setProviders(this.providers.fetched(data));
    return data;
  }

  public async getProviderGames(id: number): Promise<void> {
    this.setProviderGames(this.providerGames.fetching());
    const result = await providersController.getProviderGames(id);
    this.setProviderGames(this.providerGames.fetched(result));
  }
}
