import { getCurrentTheme } from "./../helpers";
import { scanService } from "./scan-service";
import { PostMessageTypes } from "../enums";
import { PostMessageData } from "../models";
import { rootStore } from "./../store";

class IframeService {
  public listenOnMessage(): void {
    window.onmessage = (event: MessageEvent<any>) => {
      const { data } = event;
      switch (data.type) {
        case PostMessageTypes.OpenCheckModal:
          rootStore.modalStore.setCheckModal({ open: true, data: "" });
          break;
        case PostMessageTypes.LogOut:
          rootStore.authStore.logout();
          break;
        case PostMessageTypes.OpenReturnModal:
          rootStore.modalStore.setReturnModalOpen(true);
          break;
        case PostMessageTypes.PGLoaded:
          rootStore.iFrameStore.notifyThemeChange(getCurrentTheme());
          break;
        case PostMessageTypes.OnScan:
          scanService.handleScan(data.payload);
          break;
        default:
          break;
      }
    };
  }

  public sendMessageToFrame(
    frame: HTMLIFrameElement,
    message: PostMessageData,
  ) {
    frame.contentWindow?.postMessage(message, "*");
  }
}

export const iframeService = new IframeService();
