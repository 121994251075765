import React from "react";

interface OddBallProps {
  result: string;
}
const OddBall: React.FC<OddBallProps> = ({ result }) => {
  return (
    <span className="qrResultGameAboutTxt txtBiggest txtColorWhite">
      {result}
    </span>
  );
};

export default OddBall;
