import { memo } from "react";
import { IconTypes } from "../../../../enums";

interface StatusProps {
  status: string;
  className: string;
  icon: IconTypes;
}

const Status: React.FC<StatusProps> = ({ status, icon, className }) => {
  return (
    <div className={`qrTicketStatus ${className}`}>
      <span className="ticketStatus">{status}</span>
      <i className={`${icon} ticketStatusIcon`} />
    </div>
  );
};

export default memo(Status);
