import React from "react";
import { Route, Routes } from "react-router-dom";

import { ModalContainer, Providers, Toaster, Splash } from "./containers";
import { Print } from "./components/global";
import { AppContainer } from "./containers";
import { AuthRoutes } from "../routing";

interface AppWrapperProps {}

export const AppWrapper: React.FC<AppWrapperProps> = () => {
  return (
    <AppContainer>
      <Providers>
        <Splash>
          <Routes>
            {AuthRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<route.component />}
              />
            ))}
          </Routes>
          <Toaster />
          <ModalContainer />
        </Splash>
        <Print />
      </Providers>
    </AppContainer>
  );
};
