import { rouletteData, spinNumbers } from "../constants/games";

export const getNextBeforeNumbersDataRoulette = (winNumber: number): any => {
  const winNumberIndex = spinNumbers.indexOf(winNumber);
  const nextNumber =
    spinNumbers[winNumberIndex - 1] || spinNumbers[spinNumbers.length - 1];

  const beforeNumber = spinNumbers[winNumberIndex + 1] || spinNumbers[0];

  return winNumberIndex > -1
    ? {
        beforeNumber,
        nextNumber,
        nextNumberColor: rouletteData[nextNumber].color,
        beforeNumberColor: rouletteData[beforeNumber].color,
        winNumberColor: rouletteData[winNumber].color,
        sector: rouletteData[winNumberIndex].sector,
      }
    : null;
};
