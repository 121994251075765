import { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";

import { BetType, Ticket, TicketAccepted } from "../../../../models";
import { PrintTypes } from "../../../../enums";
import { useStore } from "../../../../hooks";
import { WinTicket } from "./win-ticket";
import BetTicket from "./bet-ticket";
import { toJS } from "mobx";

interface PrintProps {}

export const Print: React.FC<PrintProps> = observer(() => {
  const {
    ticketsStore,
    partnerStore: { ticketConfig },
  } = useStore();

  const { ticketPrintData, resetTicketPrintData } = ticketsStore;
  const { type, data } = ticketPrintData;

  const isExpress = data?.betType === BetType.Express;

  console.log(toJS(data));
  useEffect(() => {
    window.addEventListener("afterprint", () => {
      resetTicketPrintData();
    });

    return () => {
      return window.removeEventListener("afterprint", () => {
        resetTicketPrintData();
      });
    };
  }, []);

  const printTypeComponent = useMemo(() => {
    return {
      [PrintTypes.None]: null,
      [PrintTypes.Bet]: (
        <BetTicket
          isExpress={isExpress}
          data={data as TicketAccepted}
          ticketConfig={ticketConfig}
        />
      ),
      [PrintTypes.Win]: (
        <WinTicket
          data={data as Ticket}
          isExpress={isExpress}
          ticketConfig={ticketConfig}
        />
      ),
    };
  }, [data]);

  return <>{data ? printTypeComponent[type] : null}</>;
});
