import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';

import {Loader} from '../../components/shared';
import {combineQuery} from '../../../helpers';
import {env} from '../../../environment';
import {useStore} from '../../../hooks';
import {Simulate} from 'react-dom/test-utils';
import error = Simulate.error;
import {checkResponseStatusAndLogOut} from '../../../api/checkResponseStatusAndLogOut';

interface GameProps {
	visible: boolean;
}

const Game: React.FC<GameProps> = observer(({
																							visible
																						}) => {

	const {
		authStore,
		iFrameStore,
		translationsStore: {selectedLanguageCode},
		providersStore: {providers: {data: {providers}}},
	} = useStore();

	const {providerToken: {data: providerToken, isLoading: providerTokenLoading}, resetProviderToken} = authStore;

	const [frameLoading, setFrameLoading] = useState(true);

	const provider = useMemo(() => {
		const [first] = providers;
		return first;
	}, [providers]);

	useEffect(() => {
		setFrameLoading(true);
	}, [selectedLanguageCode]);

	useEffect(() => {
		if (provider) {
			authStore.getProviderToken(provider.providerId).then(r => r).catch((error) => {
				checkResponseStatusAndLogOut()
			});
		}
	}, [provider, selectedLanguageCode]);

	const providerSrc = useMemo(() => {
		if (!provider || !providerToken) return '';
		const query = {
			devMode: env.devMode,
			token: providerToken,
			showCheckButton: true,
			hideThemeSwitcher: true,
			culture: selectedLanguageCode,
			partnerId: provider.partnerExternalId
		};
		const src = `${provider.launchUrl}${combineQuery(query)}`;
		return src;
	}, [provider, selectedLanguageCode, providerToken]);

	const handleFrameLoad = useCallback((event: React.SyntheticEvent<HTMLIFrameElement>) => {
		const target: HTMLIFrameElement = event.currentTarget;
		iFrameStore.setFrame(target);
		setFrameLoading(false);
	}, []);

	return (
		<div className={`game-wrapper ${visible ? '' : 'd-none'}`}>
			{
				(frameLoading || providerTokenLoading) && (
					<Loader/>
				)
			}
			{
				!!providerSrc && (
					<iframe
						src={providerSrc}
						onLoad={handleFrameLoad}
						className={`bet-shop-provider ${frameLoading ? 'd-none' : ''}`}
					/>
				)
			}
		</div>
	);
});

export default Game;
