import { memo } from "react";
import { useTranslation } from "../../../../hooks";
import {
  BetStatusClassNames,
  BetStatusIconNames,
  BetStatusNames,
  T,
} from "../../../../constants";
import Status from "../status";
import { BetStatus } from "../../../../enums";
import {
  BetShopBetTicketInfoResult,
  BetShopRoundResult,
} from "../../../../models";

interface BetItemProps {
  data: BetShopBetTicketInfoResult;
  openResultSection: (betResult: BetShopRoundResult) => void;
}

const BetItem: React.FC<BetItemProps> = ({ data, openResultSection }) => {
  const t = useTranslation();

  const collectResultData = () => {
    const resultData: any = { ...data.betShopRoundResult };
    if (data.eventName) {
      resultData.eventName = data.eventName;
    }

    openResultSection(resultData);
  };

  return (
    <div className="qrTicketGameHolder">
      <div
        className={`qrTicketGameHeader ${BetStatusClassNames[data.betStatus]}`}
      >
        <div className="qrTicketGameHolderInner">
          <span className="qrTicketGameName">{data.gameName}</span>
          <span className="qrTicketGameId">
            ID: {data.leagueDay ? `${t(T.DAY)} ${data.leagueDay} |` : ""}{" "}
            {data.roundId}
          </span>
        </div>
        <Status
          status={BetStatusNames[data.betStatus]}
          icon={BetStatusIconNames[data.betStatus]}
          className={BetStatusClassNames[data.betStatus]}
        />
      </div>
      <div className="qrTicketTeamsCheck">
        {data.eventName ? (
          <span className="qrTicketTeamsNames">{data.eventName}</span>
        ) : null}
        <span className="qrTicketTeamsNames">{t(data.marketName)}</span>
        {data.selectionName ? (
          <div className="qrTicketCheckMultiplier">
            <span className="qrTicketCheckMultiplierTxt textBold">
              {`${t(data.selectionName)} ${data.handicap || ""}`}
            </span>
            <span className="qrTicketCheckBalanceTxt multiplier">
              {data.odd}
            </span>
          </div>
        ) : null}
        <div className="qrTicketCheckMultiplier">
          <span className="qrTicketCheckMultiplierTxt textThin">Stake</span>
          <span className="qrTicketCheckBalanceTxt balance">{`${data.amount} ${data.currencyCode}`}</span>
        </div>
        <div className="qrTicketCheckMultiplier">
          <span className="qrTicketCheckMultiplierTxt textThin">
            {t(
              data.betStatus === BetStatus.Pending
                ? T.POSSIBLE_WIN
                : T.WINNING_AMOUNT,
            )}
          </span>
          <span className="qrTicketCheckBalanceTxt balance">{`${data.winAmount} ${data.currencyCode}`}</span>
        </div>
      </div>
      <div className="qrTicketCheckMultiplierFooter">
        <div
          className={`qrTicketCheckFooterResult ${data.betStatus === BetStatus.Pending ? "disabled" : null}`}
          onClick={collectResultData}
        >
          <i className="icon-fairnessIcon qrBetsHeaderBtnIcon small" />
          <span className="qrTicketCheckFooterTxt">{t(T.RESULT)}</span>
        </div>
        <div className="qrTicketCheckFooterButton">
          <i className="icon-right-stroke iconStroke" />
        </div>
      </div>
    </div>
  );
};

export default memo(BetItem);
