import {NavLink} from 'react-router-dom';
import {NAVIGATION_ITEMS, RoutePaths} from '../../../../../../constants';
import {useTranslation} from '../../../../../../hooks';
import {Icon} from '../../../../shared';
import {DRAW_SECOND_LOGO} from '../../../../../../constants/host-name';

interface NavigationProps {

}

export const Navigation: React.FC<NavigationProps> = () => {
	const t = useTranslation();

	return (
		<>
			<NavLink
				to={RoutePaths.Game}
				className="header-logo-container"
			>
				<span className="gravity-logo-in-header"/>
			</NavLink>

			{
				DRAW_SECOND_LOGO ? <NavLink
					to={RoutePaths.Game}
					className="second-logo-container"
				>
					<span className="second-logo-in-header"/>
				</NavLink> : null
			}

			{
				NAVIGATION_ITEMS.map(({path, icon, label}) => (
					<NavLink
						to={path}
						key={path}
						className={({isActive}) => `header-left-side-column ${isActive ? 'active' : ''}`}
					>
						<p className="header-icon-container">
							<Icon type={icon}/>
						</p>
						<span className="header-link-title">{t(label)}</span>
					</NavLink>
				))
			}
		</>
	);
};
