import { memo } from "react";
import { useTranslation } from "../../../../hooks";
import { T } from "../../../../constants";

interface FooterProps {
  possibleWin: number;
  currencyCode: string;
  totalBetAmount: number;
}

const Footer: React.FC<FooterProps> = ({
  currencyCode,
  possibleWin,
  totalBetAmount,
}) => {
  const t = useTranslation();

  return (
    <div className="wrapperFooter">
      <div className="wrapperFooterContainer">
        <span className="wrapperFooterTxt">{t(T.TOTAL_BET)}</span>
        <span className="wrapperFooterBalance">{`${totalBetAmount} ${currencyCode}`}</span>
      </div>
      <div className="wrapperFooterContainer">
        <span className="wrapperFooterTxt">{t(T.POSSIBLE_WIN)}</span>
        <span className="wrapperFooterBalance">{`${possibleWin} ${currencyCode}`}</span>
      </div>
    </div>
  );
};

export default memo(Footer);
