import doubleGreen from "../../../../../assets/images/QRimages/doublewheelGreen.png";
import doublePurple from "../../../../../assets/images/QRimages/doublewheelPurple.png";
import arrowImg from "../../../../../assets/images/QRimages/doubleWheelArrows.png";

const DoubleWheel = () => {
  return (
    <>
      <div className="qrResultDoubleWheelHolder">
        <div className="qrResultDoubleWheelImgHolder">
          <img className="doubleWheelImg" src={doubleGreen} alt="image" />
          <span className="qrResultDoubleWheelMultiplierTxt txtLeft">x55</span>
        </div>
        <div className="qrResultDoubleWheelImgHolder">
          <img className="doubleWheelImg" src={doublePurple} alt="image" />
          <span className="qrResultDoubleWheelMultiplierTxt txtRight">x25</span>
        </div>
        <div className="doubleWheelArrowImgHolder">
          <img className="doubleArrow" src={arrowImg} alt="image" />
        </div>
      </div>
    </>
  );
};

export default DoubleWheel;
